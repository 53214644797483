import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { BiCodeAlt } from "react-icons/bi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaClipboardCheck, FaInfoCircle } from "react-icons/fa";
import { RiGatsbyFill } from "react-icons/ri";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import OASection from "../components/our-approach-section";
import PricingCard from "../components/pricing-card";
import SeoMapSection from "../components/seo-map-section";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const SustainablePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			ellaImg: wpMediaItem(title: { eq: "Ellaroberta-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wantToImg: wpMediaItem(title: { eq: "want-to-create" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			greenHostingImg: wpMediaItem(title: { eq: "green-hosting" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			guaranteeImg: wpMediaItem(title: { eq: "A Bridge to Completion" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Sustainable" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			wpSustainableWebsitesPage {
				sustainableWebDesignPageFields {
					bookAMeetingSection {
						description
						title
					}
					buildAWebsiteSection {
						title
						smallTitle
						description
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					ecoFriendlySection {
						title
						smallTitle
						description
						button {
							text
							link
						}
					}
					heroSection {
						title
						description
						button2 {
							text
							link
						}
						button1 {
							text
							link
						}
					}
					hostYourWebsiteSection {
						description
						title
						smallTitle
						button {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					fourStepSection {
						title
						smallTitle
						button {
							link
							text
						}
						step1 {
							description
							title
						}
						step2 {
							description
							title
						}
						step3 {
							description
							title
						}
						step4 {
							description
							title
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					howWeBuildSection {
						button {
							link
							text
						}
						description
						smallTitle
						title
						step1 {
							title
							description
						}
						step2 {
							title
							description
						}
						step3 {
							title
							description
						}
						step4 {
							title
							description
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const {
		wpSustainableWebsitesPage: {
			sustainableWebDesignPageFields: {
				bookAMeetingSection,
				buildAWebsiteSection,
				ecoFriendlySection,
				heroSection,
				hostYourWebsiteSection,
				howWeBuildSection,
				fourStepSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Sustainable Web Design London",
				item: {
					url: `${siteUrl}/sustainable-web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/sustainable-web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						mobileButton2
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#accessible"
								>
									Accessible
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#sustainable"
								>
									Sustainable
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#profit"
								>
									Profitable
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey pt-5 pt-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7 justify-content-between">
							<Col lg={6} className="">
								<p className="ssp-bold text-uppercase text-light-blue">
									{buildAWebsiteSection.smallTitle}
								</p>
								<div>{parse(buildAWebsiteSection.title)}</div>
								<div>{parse(buildAWebsiteSection.description)}</div>
								<Button
									className={`mt-4  fs-5 w-100 d-none d-md-inline-block w-md-auto cta-btn py-2 px-4 white-link-yellow `}
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={buildAWebsiteSection.image.gatsbyImage}
										alt={buildAWebsiteSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<Logos page="Sustainable" noTitle />
				<section id="accessible" className="bg-white py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6">
							<Col lg={6} className=" ">
								<p className="ssp-bold text-uppercase text-light-blue">
									{ecoFriendlySection.smallTitle}
								</p>
								<div>{parse(ecoFriendlySection.title)}</div>
								<div>{parse(ecoFriendlySection.description)}</div>

								<Button
									className={`mt-4  fs-5 w-100 d-none d-md-inline-block w-md-auto cta-btn py-2 px-4 white-link-yellow `}
									variant="primary"
									as={Link}
									to={ecoFriendlySection.button.link}
								>
									{ecoFriendlySection.button.text}
								</Button>
							</Col>

							<Col className="mt-4 mt-lg-0" lg={{ span: 6, order: "first" }}>
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src="https://www.youtube.com/embed/3UItovf8im4?si=3Iml2iPLlicjhC4c"
										title="Is the Internet Really a Bigger Polluter than the Aviation Industry?"
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
								<Button
									className={`mt-5  fs-5 w-100  d-md-none w-md-auto cta-btn py-2 px-4 white-link-yellow `}
									variant="primary"
									as={Link}
									to={ecoFriendlySection.button.link}
								>
									{ecoFriendlySection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									{howWeBuildSection.smallTitle}
								</p>
								<div>{parse(howWeBuildSection.title)}</div>
								<div>{parse(howWeBuildSection.description)}</div>
							</Col>
						</Row>
						<Row className="my-5 gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BsFillLightningChargeFill className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{howWeBuildSection.step1.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{howWeBuildSection.step1.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BiCodeAlt className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{howWeBuildSection.step2.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{howWeBuildSection.step2.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaClipboardCheck className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{howWeBuildSection.step3.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{howWeBuildSection.step3.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<RiGatsbyFill className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{howWeBuildSection.step4.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{howWeBuildSection.step4.description}
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									href={howWeBuildSection.button.link}
								>
									{howWeBuildSection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <section className="pt-5 pt-lg-7">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row id="pricing">
							<Col>
								<p className="ssp-bold text-center text-light-blue">
									HOW MUCH DOES SUSTAINABLE WEB DESIGN LONDON COST?
								</p>
								<h2 className="text-primary display-5  text-center pb-5">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								btnColour="secondary"
								title="Essential"
								subText="Convert your existing website into a lean, green, energy saving machine!"
								price="£85"
								monthly
								setupFee="£995"
								features={[
									"Rebuild up to 15 pages + unlimited blog posts",

									"GatsbyJS website",
									"WordPress Content Management System",

									"Unlimited images, audio and file content",
									"Green hosting",
									"SSL Certificate",
									"Custom Forms",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								btnColour="dark-blue"
								middle
								title="Premium"
								subText={
									<span>
										Build a brand-new website with a fresh, clean and modern
										design
									</span>
								}
								price="£115"
								monthly
								setupFee="£1245"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Essential</span>
									</span>,
									"Bespoke design",
									"Up to 30 pages + Blog content",

									"Monthly Analytics Report",
									"Basic SEO",
								]}
							/>
							<PricingCard
								btnColour="light-blue"
								title="Pro"
								subText="Build a brand-new website with advanced functionality"
								price="£145"
								monthly
								setupFee="£1995"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Premium</span>
									</span>,
									"Up to 100 pages",
									"Add E-Commerce or take online payments",
									"Sell online courses or membership section",

									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Add user login for gated content{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Maximum user limits apply
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"Advanced SEO advice and implementation",
								]}
							/>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 py-lg-7" id="pricing">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row>
							<Col>
								<h2 className="pb-5 text-center text-primary display-5">
									Pricing
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								btnColour="secondary"
								title="Initium"
								subText="For businesses and start-ups launching smaller projects, individual campaigns, or landing pages."
								price="AED 14,995"
								monthly
								setupFee="AED 495"
								features={[
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Unlimited page website{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Our websites are powered by reusable ACF and
															ReactJS components, so you can create and build as
															many pages as you want, without requiring RJM
															support
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 5 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Typical pages include: Home, About, Services, Blog
															and Contact
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Mobile-responsive design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully managed website, including maintenance{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"GatsbyJS frontend",
									"Headless WordPress Content Management System",
									"Unlimited images, audio and file content",
									"Fast and secure web hosting on Netlify",
									"72 hour support response time",
									"SSL certificate",
									"Custom forms using Formspree",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								btnColour="dark-blue"
								middle
								title="Proventus"
								subText={
									<span>
										For businesses ready to expand their online presence and
										scale.
									</span>
								}
								price="AED 24,995"
								monthly
								setupFee="AED 895"
								features={[
									<span>
										Everything in <strong>Initium</strong>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 10 custom pages designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Typical pages include: Home, About, Services,
															Blog, Contact, Case studies, FAQ,
															Portfolio/Customers, Google Ad and local SEO pages{" "}
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Fully bespoke design tailored to your brand",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Additional 10 custom components designed{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Includes all pre-built RJM components{" "}
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Advanced SEO Implementation{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															We will create the Meta TItle, Meta Description,
															Schema Mark-up and Open Graph content for each
															page
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Zapier integrations for automated workflows{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Connect form submissions and other data to your
															CRM and other marketing tools
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"48 hour support response time",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Copywriting support{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Complete website copywriting, including 2
															revisions
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
								]}
							/>
							<PricingCard
								btnColour="light-blue"
								title="Optimus"
								subText="For established businesses and enterprises needing an advanced solution."
								price="AED 43,995"
								monthly
								setupFee="AED 1,445"
								features={[
									<span>
										Everything in <strong>Proventus</strong>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Up to 50 custom pages and components{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Includes all RJM pre-built components
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									// <span className="d-flex align-items-center">
									// 	<p className="pb-0 mb-0 d-inline-block ssp-regular">
									// 		Deep custom integrations{" "}
									// 		<OverlayTrigger
									// 			placement="top"
									// 			overlay={
									// 				<Popover id={`popover-positioned-top`}>
									// 					<Popover.Body>
									// 						CRM, API's and other marketing platforms
									// 					</Popover.Body>
									// 				</Popover>
									// 			}
									// 		>
									// 			<FaInfoCircle
									// 				style={{ width: "10px", color: "#40B4E5" }}
									// 				className="ms-2 d-inline-block"
									// 			/>
									// 		</OverlayTrigger>
									// 	</p>
									// </span>,

									"24 SLA support response time",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Full copywriting included{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Complete website copywriting, including 3
															revisions
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
								]}
							/>
						</Row>
					</Container>
				</section>

				<section id="guarantee" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
									Love Your New Sustainable Website— Or Your Money Back!
								</h2>

								<p>
									Tired of bland, cookie-cutter websites that barely move the
									needle?
								</p>
								<p>
									With our “Love It or Your Money Back Guarantee,” you’re not
									risking a single Dirham. We deliver a low-carbon, sustainable
									designed websites crafted specifically for companies who are
									carbon conscious but want to attract leads, build trust, and
									boost their bottom line.{" "}
								</p>
								<p>
									If you’re not over-the-moon, thrilled with our design or you
									feel it doesn’t set you apart, we’ll hand back every Dirham
									you paid. Why settle for mediocrity when you can demand
									excellence—guaranteed?
								</p>

								<Button
									className="px-4 py-2 mt-4  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
							<Col lg={5}>
								<div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.guaranteeImg.gatsbyImage}
										alt={data.guaranteeImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="approach">
					<OASection
						customHeading
						buttonUrl={fourStepSection.button.link}
						buttonText={fourStepSection.button.text}
						textClass="text-center"
						smallHeading={fourStepSection.smallTitle}
						heading={fourStepSection.title}
						imgHeight="60rem"
						image={fourStepSection.image.gatsbyImage}
						imageAlt={fourStepSection.image.altText}
						heading1={fourStepSection.step1.title}
						heading2={fourStepSection.step2.title}
						heading3={fourStepSection.step3.title}
						heading4={fourStepSection.step4.title}
						launch={fourStepSection.step4.description}
						build={fourStepSection.step3.description}
						design={fourStepSection.step2.description}
						brief={fourStepSection.step1.description}
					/>
				</div>
				{/* <section>
				<TeamSection/>
				</section> */}
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
						<Row>
							<Col className="text-center">
								<h3 className="text-primary">Request a call back</h3>
								<p>
									Send us your contact details and we will call you straight
									back
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4507.7454215679345!2d55.147461899999996!3d25.077310599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d5f7304c865%3A0xa891c5ac1cc02331!2sRJM%20Digital!5e1!3m2!1sen!2sae!4v1737018767473!5m2!1sen!2sae" />
				<Faq page="Sustainable" />
				{/* <OSSection
					link3="/branding"
					text3="BRANDING"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default SustainablePage;
